<template>
  <v-sheet min-height="300" class="d-flex flex-column">
    <section v-if="!loading">
      <div class="">
        <breadcrumbs
          :items="[
            // {
            //   text: 'Create',
            //   disabled: false,
            //   to: { name: 'Instructor Assessment Rubrics', query: { tab: 0 } },
            // },
            {
              text: 'My Rubrics',
              disabled: false,
              to: { name: 'Instructor Assessment Rubrics', query: { tab: 1 } },
            },
            {
              text: rubric.title,
              disabled: true,
              to: null,
            },
          ]"
        />
      </div>
      <v-divider />
      <div class="pa-5">
        <RubricForm :rubric="rubric" action="update" :type="rubric.type_of_rubric" />
      </div>
    </section>
    <circular v-else  class="ma-auto"/>
  </v-sheet>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RubricForm from "../../components/teacher/rubrics/RubricForm.vue";
export default {
  components: {
    RubricForm,
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapState("instructor", {
      rubric: (state) => state.rubrics,
    }),
  },
  created() {
    this.showRubricAction(this.$route.params.id).then(() => {
      this.loading = false;
    });
  },
  methods: {
    ...mapActions("instructor", ["showRubricAction", "getRubricsAction"]),
  },

  beforeRouteLeave(to, from, next) {
    this.getRubricsAction().then(() => {
      next();
    });
  },
};
</script>

<style></style>
